.blogContainer{
    margin-bottom: 1em;
}

#blogTitle{
    padding-top: 1em;
    padding-bottom: 2em;
    width: 100%;
    margin:auto;
    text-align: center;
    font-size: 3em;
    font-weight: bold;
}

.blogGap{
    margin-top: 1em;
}

.blogGapEmpty{
    margin-top: 1em;
    margin-bottom: 30%;
}

#blogLeftTitle{
    text-align: right;
    margin: auto;
}

#blogRightTitle{
    text-align: left;
    margin: auto;
}

#blogLeftTitle img,
#blogRightTitle img{
    width: 75%;
}

.articleSubTitle{
    font-size: 1.5em;
    text-align: left;
    color: rgba(255,255,255,0.7);
}

.articleText{
    font-size: 1.3em;
    text-align: left;
}

.articleContainer{
    margin: auto;
    width: 95%;
    min-height: 84%;
    padding: 1.2em;
    background-color: black;
    border-radius: 1em;
    border-style: solid;
    border-width: medium;
    border-color: darkgrey;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 3%;
}

#articleTitle{
    padding-top: 1em;
    width: 100%;
    margin:0;
    text-align: left;
    font-size: 3em;
    font-weight: bold;
}

#articleVideo{
    border-color: white;
    border-style: solid;
    border-width: 0.1em;
    background-color: var(--background);
    height: 30em;
    min-width: 10em;
    margin: auto;
    padding: 1em;
}


