#welcomeContainer{
    margin-bottom: 1em;
}

#welcomeBG{
    height: 100%;
    position: absolute;
}

#welcomeTitle{
    padding-top: 1em;
    padding-bottom: 2em;
    width: 100%;
    text-align: center;
}

#welcomeTitle img{
    width: 60%;
    margin: auto;
}

#welcomeVideo{
    border-color: white;
    border-style: solid;
    border-width: 0.1em;
    background-color: var(--background);
    height: 30em;
    width: 10em;
    margin: auto;
    padding: 1em;
}

.welcomeButton{
    background-color: var(--green);
    width: 8.5em;
    margin: auto;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-align: center;
    padding: 0.2em;
    border-radius: 0.2em;
    border-color: white;
    border-width: 0.1em;
    border-style: solid;
    font-size: 2.5em;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.welcomeButton:hover,
.welcomeButton:active{
    cursor: pointer;
    opacity: 1;
    -webkit-filter: brightness(140%);
    filter: brightness(140%);
}