@import url( 'https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');
@import '../node_modules/antd/dist/antd.css';

/* Variables */
:root {
    --light-blue: #6f7fb5;
    --navy-blue: #1b2757;
    --light-navy-blue: #273e99;
    --dark-blue: #0B0D2B;
    --green: #20BF55;
    --background: #000;
    --fontcolor: #ffffff;
}

@font-face {
    font-family: aesprite;
    src: url('./App/fonts/Aseprite-Regular.woff2');
  }
  

/* Repeating HTML Elements */

body,
html,
#root,
.container-fluid {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
    font-family: aesprite, monospace !important;
    color: var(--fontcolor) !important;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--fontcolor) !important;
}

.particles{
    z-index: -1 !important;
    position: fixed;
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
    background-image: url('./App/assets/BG/starField.png');
    background-repeat: repeat;
    background-size: auto;
    background-position: center;
    background-blend-mode: lighten;
    background-color: rgba(0, 0, 0, 0) !important;
}

.particles2{
    z-index: -1 !important;
    position: fixed;
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
    background-image: url('./App/assets/BG/starField2.png');
    background-repeat: repeat;
    background-position: center;
    background-blend-mode: lighten;
    background-color: rgba(0, 0, 0, 0) !important;
    animation: animStar 90s linear infinite;
}

@keyframes animStar {
    from {
        top: -120%;
        left: -120%;
    }
    to {
        top: 120%;
        left: 120%;
    }
  }